import React, { useEffect, useState } from "react";
import ServiceTable from "../../tables/service-table/ServiceTable";
import useFetch from "../../../hooks/useFetch";

const ServiceList = () => {
  const [fetchServiceLoading, setFetchServiceLoading] = useState(false);
  const [fetchServiceError, setFetchServiceError] = useState(null);
  const [fetchServiceRes, setFetchServiceRes] = useState(null);

  const { get: getService, reset: resetGetService } = useFetch(
    "api/driver/services",
    {
      setLoading: setFetchServiceLoading,
      setError: setFetchServiceError,
      setData: setFetchServiceRes,
    }
  );

  // Initial fetch of service data
  useEffect(() => {
    getService();
  }, []);

  return (
    <>
      <ServiceTable
        serviceData={fetchServiceRes}
        fetchServiceLoading={fetchServiceLoading}
      />
    </>
  );
};

export default ServiceList;

// AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import ErrorRoutes from "./ErrorRoutes";
import ServiceGroups from "../pages/app/servicesGroups/Services";

const Dashboard = lazy(() => import("../pages/app/dashboard/Dashboard"));
const AddService = lazy(() => import("../pages/app/registration/Service"));
const ManageUser = lazy(() => import("../pages/app/manage/PassengerManage"));
const DriversPassenger = lazy(() =>
  import("../pages/app/passengers/Passengers")
);
const AddDriver = lazy(() => import("../pages/app/registration/Driver"));
const AddStaff = lazy(() => import("../pages/app/registration/Staff"));
const PassengerPaymentManage = lazy(() =>
  import("../pages/app/payment/PassengerPaymentManage")
);
const PassengerPayment = lazy(() =>
  import("../pages/app/payment/PassengerPayment")
);

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Services */}
        <Route path="/addService" element={<AddService />} />
        {/* Passenger */}
        <Route path="/addDriver" element={<AddDriver />} />
        <Route path="/addStaff" element={<AddStaff />} />
        <Route path="/managePassenger" element={<ManageUser />} />

        {/* driver */}

        <Route path="/driver/passengers" element={<DriversPassenger />} />
        <Route path="/driver/services" element={<ServiceGroups />} />

        {/* Passenger */}

        <Route path="/my-payments" element={<PassengerPayment />} />

        <Route path="/passengerPayment" element={<PassengerPaymentManage />} />
        <Route path="*" element={<ErrorRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { AccAuthProvider } from "./context/UserAccountAuth";

// Create the root using createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AccAuthProvider>
      <App />
    </AccAuthProvider>
  </React.StrictMode>
);

import { MenuOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <ul id="side-menu" className={`sidebar ${isOpen ? "open" : ""}`}>
      <li>
        <button className="btn-bars" onClick={toggleSidebar}>
          <MenuOutlined />
        </button>
      </li>
      <li>
        <Link to="/" onClick={toggleSidebar} className="current">
          Home
        </Link>
      </li>
      <li>
        <Link to="/about" onClick={toggleSidebar}>
          About
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;

// AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import "../components/web/web-css/style.css";
import "../components/web/web-css/mobile.css";
import ErrorRoutes from "./ErrorRoutes";
import SignUp from "../pages/web/sign-up/SignUp";

const Home = lazy(() => import("../pages/web/home/Home"));
const About = lazy(() => import("../pages/web/about/About"));
const Login = lazy(() => import("../pages/web/login/Login"));

const WebRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="*" element={<ErrorRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default WebRoutes;

import React from "react";
import { Layout } from "antd";
import PassengerSider from "../sider/PassengerSider";
import StaffSider from "../sider/StaffSider";
import DriverSider from "../sider/DriverSider";
import Header from "../header/Header";
import BreadcrumbComp from "../breadcrumb/Breadcrumb"; // Adjust the import path accordingly

const { Content } = Layout;

const LayoutComponent = ({ children }) => {
  const userRole = localStorage.getItem("role");
  let Sider;
  if (userRole) {
    switch (userRole) {
      case "staff":
        Sider = <StaffSider />;
        break;
      case "passenger":
        Sider = <PassengerSider />;
        break;
      case "driver":
        Sider = <DriverSider />;
        break;
    }
  }
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {Sider}
      <Layout>
        <Header />
        <Layout className="layout-content">
          <BreadcrumbComp />
          <Content
            style={{
              margin: "24px 16px 0",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;

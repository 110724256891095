import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="main-footer" className="py-2">
      <div className="container footer-container">
        <div>
          <img
            src="/images/web-images/abro-taxi-logo.jpg"
            alt="Abro Taxi Logo"
          />
          <p>
            ABRO Taxi: Your ride, your way—experience the perfect blend of speed
            and comfort. Whether it's a quick trip across town or a journey to a
            special destination, our friendly drivers are dedicated to making
            every ride a memorable one. Trust us to get you there safely and on
            time!
          </p>
        </div>
        <div>
          <h3>Email Tofi Technologies Center</h3>
          <p>
            <i className="fa-solid fa-envelope fa-1x"></i>
            <a href="mailto:tofitechno@gmail.com">tofitechno@gmail.com</a>
            <br />
            <i className="fa-solid fa-phone"></i>
            <a href="tel:+251902077707">+251902077707</a> /{" "}
            <a href="tel:+251118327335">+251118327335</a>
            <br />
          </p>
        </div>
        <div>
          <h3>Site Links</h3>
          <ul className="list">
            <li>
              <a href="tel:+251902077707">Help & Support</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2>Join Us</h2>
          <p>
            Join ABRO Taxi and be part of a team that prioritizes speed,
            comfort, and exceptional service. Enjoy flexible hours, competitive
            earnings, and the freedom of the road while making a positive impact
            in our community. Drive with us today!
          </p>
          <a
            href="tel:+251902077707"
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register Now
          </a>
        </div>
        <p>Copyright &copy; 2023, All Right Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;

export function generateRandomHexColors(numColors) {
  const colorList = [];
  for (let i = 0; i < numColors; i++) {
    let hexCode = "#";
    // Generate 6 random hexadecimal digits
    for (let j = 0; j < 6; j++) {
      hexCode += Math.floor(Math.random() * 16).toString(16);
    }
    colorList.push(hexCode);
  }
  return colorList;
}

export const colorData = ["#059bff", "#ff4069", "#059bff", "#ff4069"];

export const handleFilterNumber = (e) => {
  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
  }
};

export const convertToDisplayDate = (date) => {
  const dateObj = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(dateObj);
};

export function errorHandler(error, showAlert = false) {
  // Log the error to the console
  console.error("An error occurred:", error.message || error);

  // Optionally display an alert to the user
  if (showAlert) {
    alert("Something went wrong. Please try again later.");
  }

  // You can also add additional error handling logic here
  // e.g., sending the error to an external logging service
}

// Validates a phone number input
export function inputValidator(phone) {
  let isValid = true;
  let errorMessages = [];

  // Check if phone number is provided
  if (!phone || phone.trim() === "") {
    isValid = false;
    errorMessages.push("Phone number is required.");
  }

  // Check if phone number matches the desired pattern (e.g., digits only, specific length)
  const phonePattern = /^\d{10}$/; // Example pattern: 10 digits
  if (!phonePattern.test(phone)) {
    isValid = false;
    errorMessages.push("Phone number must be 10 digits.");
  }

  return {
    isValid,
    errorMessages,
  };
}

export const findFromList = function (lists, searchValue) {
  if (!searchValue) return [];

  const searchResults =
    lists &&
    lists.filter(function (list) {
      return (
        (searchValue && list.slice(0, searchValue.length) === searchValue) ||
        list.slice(0, searchValue.length) ===
          searchValue[0]?.toUpperCase() + searchValue.slice(1)
      );
    });
  return searchResults?.slice(0, 5);
};

export const findFromServiceList = function (lists, searchValue) {
  if (searchValue) {
    const searchResults =
      lists &&
      lists.filter(function (list) {
        console.log(list);
        return (
          (searchValue && list.slice(0, searchValue.length) === searchValue) ||
          list.slice(0, searchValue.length) ===
            searchValue[0]?.toUpperCase() + searchValue.slice(1) ||
          list.includes(searchValue) ||
          list.includes(searchValue?.toUpperCase())
        );
      });
    return searchResults.slice(0, 5);
  }
  return [];
};

export const checkAndNavigate = (location, navigate) => {
  const lastUrl = localStorage.getItem("lastUrl");
  const token = localStorage.getItem("accessToken");
  if (!token && lastUrl?.includes("/app")) {
    return navigate("/login");
  }
  navigate(lastUrl || "/");
};

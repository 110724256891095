import React from "react";
import LayoutComponent from "../../../components/layout/Layout";
import NotFound from "../../error/404";
import ServiceList from "../../../components/contents/services-groups/ServicesList";

const ServiceGroups = () => {
  const userRole = localStorage.getItem("role");

  let Comp;
  if (userRole) {
    switch (userRole) {
      case "driver":
        Comp = <ServiceList />;
        break;
      default:
        Comp = <NotFound />;
    }
  }

  return <LayoutComponent>{Comp}</LayoutComponent>;
};

export default ServiceGroups;

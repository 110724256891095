import React, { useState, useEffect } from "react";
import { Select, Spin, Form } from "antd";
import useFetch from "../../hooks/useFetch";

const { Option } = Select;

const DepartureAndDropOffSelector = ({
  form,
  setSelectedDeparture = () => {},
  setSelectedDropOff = () => {},
}) => {
  const [locationSearch, setLocationSearch] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [searchedLocations, setSearchedLocations] = useState([]);

  const { get: getLocations } = useFetch("auth/locations/search", {
    setLoading: setLocationLoading,
    setData: setLocations,
    setError: setLocationError,
  });

  useEffect(() => {
    if (locationSearch) {
      getLocations({ search: locationSearch });
    }
  }, [locationSearch]);

  useEffect(() => {
    setSearchedLocations((prevLocations) => {
      const newLocations = locations.filter(
        (location) => !prevLocations.some((prev) => prev.id === location.id)
      );
      return [...newLocations, ...prevLocations];
    });
  }, [locations]);

  return (
    <>
      <Form.Item
        name="departure"
        label="Departure"
        rules={[
          { required: true, message: "Please select a departure!" },
          {
            validator: (_, value) => {
              const dropOff = form.getFieldValue("dropOff");
              if (value && dropOff && value === dropOff) {
                return Promise.reject(
                  "Departure and Drop-off cannot be the same!"
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select departure"
          onSearch={setLocationSearch}
          onChange={(value) => {
            setSelectedDeparture?.(value);
            form.validateFields(["dropOff"]); // Re-validate dropOff when departure changes
          }}
          notFoundContent={locationLoading ? <Spin size="small" /> : null}
          filterOption={false}
          allowClear
        >
          {locations?.map((location) => (
            <Option key={location.id} value={location.placeName}>
              {location.placeName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="dropOff"
        label="Drop-off"
        dependencies={["departure"]}
        rules={[
          { required: true, message: "Please select a drop-off!" },
          {
            validator: (_, value) => {
              const departure = form.getFieldValue("departure");
              if (value && departure && value === departure) {
                return Promise.reject(
                  "Departure and Drop-off cannot be the same!"
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select drop-off"
          onSearch={setLocationSearch}
          onChange={(value) => {
            setSelectedDropOff?.(value);
            form.validateFields(["departure"]); // Re-validate departure when dropOff changes
          }}
          notFoundContent={locationLoading ? <Spin size="small" /> : null}
          filterOption={false}
          allowClear
        >
          {locations?.map((location) => (
            <Option key={location.id} value={location.placeName}>
              {location.placeName}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default DepartureAndDropOffSelector;

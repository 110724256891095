import { useEffect, useState } from "react";
import { Form, Button, message, Card, Input, Select, Row, Col } from "antd";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import DepartureAndDropOffSelector from "../departure-and-dropOff-selector/DepartureAndDropOfSelector";
import usePost from "../../hooks/usePost";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";

const SignUpForm = () => {
  const navigate = useNavigate();
  const resendInterval = 5;
  const [form] = Form.useForm();
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [codeRes, setCodeRes] = useState(null);

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginRes, setLoginRes] = useState(null);

  const [codeSent, setCodeSent] = useState(false);
  const [codeResend, setCodeResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(resendInterval);
  const [intervalId, setIntervalId] = useState(null);

  // Get the code from the server
  const { get: getCode, reset: resetGetCode } = useFetch("auth/get-code", {
    setLoading: setCodeLoading,
    setError: setCodeError,
    setData: setCodeRes,
  });

  const { post: login, reset: resetLogin } = usePost(
    "auth/passenger/verifyRegister",
    {
      setLoading: setLoginLoading,
      setError: setLoginError,
      setData: setLoginRes,
    }
  );

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      await login(values);
      localStorage.setItem("role", "passenger");
      setCodeResend(true);
      setResendTimer(resendInterval);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCode = async () => {
    try {
      const values = await form.validateFields(["phone"]);
      localStorage.setItem("phone", values.phone);
      console.log(values);
      await getCode(values);
      setCodeResend(true);
      setResendTimer(resendInterval);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const phone = localStorage.getItem("phone");
    if (phone) form.setFieldValue("phone", phone);
  }, []);

  useEffect(() => {
    if (codeResend && resendTimer > 0) {
      const id = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else if (resendTimer === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Cleanup on unmount or reset
  }, [codeResend, resendTimer]);

  useEffect(() => {
    console.log(codeRes);

    if (codeRes?.status)
      message[codeRes?.status](codeRes?.message || codeRes?.status);

    if (codeRes?.status === "success") {
      form.resetFields(["code"]);
      setCodeSent(true);
    }

    resetGetCode();
  }, [codeRes]);

  useEffect(() => {
    console.log(codeError);
    if (codeError?.message) message[codeError?.status](codeError?.message);
    resetGetCode();
  }, [codeError]);

  useEffect(() => {
    console.log(loginRes);
    if (loginRes?.message) message[loginRes?.status](loginRes?.message);

    if (loginRes?.status === "success" && loginRes?.token) {
      localStorage.setItem("accessToken", loginRes?.token);
      navigate("/app");
    }

    resetLogin();
  }, [loginRes]);

  useEffect(() => {
    console.log(loginError);
    if (loginError?.message) message[loginError?.status](loginError?.message);
    resetLogin();
  }, [loginError]);

  return (
    <div
      style={{
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card title="Sign Up" style={{ marginTop: 100, marginBottom: 30 }}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Full Name"
                name="fullName"
                rules={[
                  { required: true, message: "Please input the full name!" },
                ]}
              >
                <Input placeholder="Enter full name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
          </Row>

          <PhoneNumberInput form={form} />

          {!codeSent && (
            <Form.Item>
              <Button
                type="primary"
                loading={codeLoading}
                onClick={handleGetCode}
              >
                Get Code
              </Button>
            </Form.Item>
          )}

          {codeSent && (
            <>
              <Form.Item
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input.OTP />
              </Form.Item>

              {codeResend && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span>
                    {resendTimer > 0
                      ? `You can resend the code after ${resendTimer} seconds`
                      : "You can now resend the code"}
                  </span>
                  <Button
                    disabled={resendTimer > 0}
                    style={{ textAlign: "right" }}
                    type="link"
                    onClick={handleGetCode}
                  >
                    Resend
                  </Button>
                </div>
              )}
            </>
          )}

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Category"
                name="typeOfOrder"
                rules={[{ required: true, message: "Please select category" }]}
              >
                <Select placeholder="Select category">
                  <Option value="morning">Morning</Option>
                  <Option value="afternoon">Afternoon</Option>
                  <Option value="both">Both</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  { required: true, message: "Please select your gender" },
                ]}
              >
                <Select placeholder="Select your gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: "Please confirm your password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm your password" />
              </Form.Item>
            </Col>
          </Row>

          <DepartureAndDropOffSelector form={form} />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loginLoading}>
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SignUpForm;

import React, { useState } from "react";
import Sidebar from "./Sidebar"; // Ensure this import path is correct
import { Link } from "react-router-dom";

const Header = ({ loginVisible = true }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <header className="hero">
      <nav id="main-nav">
        <div className="container">
          <ul id="main-menu" className="nav-list main-menu">
            <li>
              <Link className="current" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>

          {loginVisible && (
            <ul id="log-in">
              <li>
                <Link to="/login" className="btn">
                  Login
                </Link>
              </li>
            </ul>
          )}
        </div>
      </nav>

      <div id="info-nav">
        <div className="container">
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <a href="/">
            <img
              className="logo"
              src="/images/web-images/abro-taxi-logo.jpg"
              alt="Abro Taxi Logo"
            />
          </a>
          <ul className="nav-list mobo-nav">
            <li>
              <a href="tel:1555555555">
                <i className="fa-solid fa-phone fa-2x"></i>555-555-555
              </a>
            </li>
            <li>
              <a href="mailto:info@demolink.org">
                <i className="fa-solid fa-envelope fa-2x"></i>
                info@demolink.org
              </a>
            </li>
            {loginVisible && (
              <li id="log-in-mobo" className="mobo-btn">
                <Link to="/login" className="btn">
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useState, useMemo, useEffect } from "react";
import { Button, Input, Row, Col, Select, Popconfirm, message } from "antd";
import { DeleteFilled, EditOutlined, PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import useDelete from "../../../hooks/useDelete";

// These are the columns you want to exclude from the rendered table,
// but they will still be available in the filtering dropdown
const excludedColsForTable = [
  "id",
  "SERVICE ID",
  "DRIVER ID",
  "DRIVER PHONE",
  "DRIVER NAME",
  "STATUS",
  "BRAND",
  "DISTANCE",
  "SERVICE NAME",
  "DEPARTURE",
  "DROP OFF",
  "UPDATED",
  "CREATED",
  "CREATED BY",
  "CATEGORY",
  "MONTHLY PAYMENT(stipend)",
  "AVAILABLE VEHICLE SEAT",
  "LEFT VEHICLE SEAT",
  "PLATE NUMBER",
  "MODEL YEAR",
];

// Generate columns that are used to display in the table
const generateColumns = (data, removeService) => {
  if (!data || data.length === 0) return [];

  // Columns with combined fields
  const combinedColumns = [
    {
      title: "SERVICE",
      key: "service",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Service Name: {record["SERVICE NAME"]}
          </div>
          <div className="tableSubCols details">
            Service ID: {record["SERVICE ID"]}
          </div>
          <div className="tableSubCols details">ID: {record["id"]}</div>
        </>
      ),
    },
    {
      title: "SERVICE DETAILS",
      key: "service-details",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Stipend (Monthly): {record["MONTHLY PAYMENT(stipend)"]} Birr
          </div>
          <div className="tableSubCols details">
            Departure: {record["DEPARTURE"]}
          </div>
          <div className="tableSubCols details">
            Drop Off: {record["DROP OFF"]}
          </div>
          <div className="tableSubCols details">
            Distance: {record["DISTANCE"]} km
          </div>
        </>
      ),
    },
    {
      title: "VEHICLE DETAILS",
      key: "vehicle-details",
      render: (text, record) => (
        <>
          <div className="tableSubCols heading-1">{record["BRAND"]}</div>
          <div className="tableSubCols details">
            Plate: {record["PLATE NUMBER"]}
          </div>
          <div className="tableSubCols details">
            Model Year: {record["MODEL YEAR"]}
          </div>
          <div className="tableSubCols details">
            Capacity: {record["AVAILABLE VEHICLE SEAT"]} Seats
          </div>
          <div className="tableSubCols details">
            Available: {record["LEFT VEHICLE SEAT"]} Left
          </div>
        </>
      ),
    },
    {
      title: "DRIVER",
      key: "driver",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Driver: {record["DRIVER NAME"]}
          </div>
          <div className="tableSubCols details">
            Phone: {record["DRIVER PHONE"]}
          </div>
          <div className="tableSubCols details">Id: {record["DRIVER ID"]}</div>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "created",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Set By: {record["CREATED BY"]}
          </div>
          <div className="tableSubCols details">
            Created: {new Date(record["CREATED"]).toLocaleDateString()}
          </div>
          <div className="tableSubCols details">
            Updated: {new Date(record["UPDATED"]).toLocaleDateString()}
          </div>
        </>
      ),
    },
  ];

  // Include columns not excluded from the table display
  const sampleItem = data[0];
  const keysForTable = Object.keys(sampleItem).filter(
    (key) => !excludedColsForTable.includes(key)
  );

  const dynamicColumns = keysForTable.map((key) => ({
    title: key.replace(/_/g, " ").toUpperCase(),
    key,
    dataIndex: key,
  }));

  // Add an actions column at the end
  dynamicColumns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) =>
      removeService && (
        <span>
          <Popconfirm
            title="Are you sure you want it to be removed!"
            onConfirm={() => {
              removeService?.(record.id);
            }}
          >
            <Button type="link">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </span>
      ),
  });

  return [...combinedColumns, ...dynamicColumns];
};

const ServiceTable = ({
  serviceData = [],
  fetchServiceLoading,
  setModalClosed,
  getService,
}) => {
  const userRole = localStorage.getItem("role");

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("DEPARTURE");

  const [removeLoading, setRemoveLoading] = useState(false);
  const [removeError, setRemoveError] = useState(null);
  const [removeRes, setRemoveRes] = useState(null);

  const { remove: deleteService, reset: resetRemoveService } = useDelete(
    "api/service",
    {
      setLoading: setRemoveLoading,
      setError: setRemoveError,
      setData: setRemoveRes,
    }
  );

  const customColumns = useMemo(
    () =>
      generateColumns(
        serviceData,
        userRole === "staff" ? deleteService : undefined
      ),
    [serviceData]
  );

  // Handle the result of the service creation
  useEffect(() => {
    if (removeRes?.message) {
      message[removeRes?.status || "success"](removeRes?.message || "");
    }

    if (removeRes?.status === "success") {
      getService(); // Fetch the updated service list
    }

    resetRemoveService();
  }, [removeRes]);

  // Handle any error during service creation
  useEffect(() => {
    if (removeError)
      message[removeError?.status || "error"](
        removeError?.message || "error removing"
      );

    resetRemoveService();
  }, [removeError]);

  // Filtering services based on search text and selected column
  const filteredData = useMemo(() => {
    if (!serviceData) return [];
    return serviceData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [serviceData, searchText, selectedColumn]);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  // Options for selecting column to filter (include every column, even excluded)
  const columnOptions = Object.keys(serviceData?.[0] || {}).map((col) => ({
    key: col,
    value: col,
    label: col.replace(/_/g, " ").toUpperCase(),
  }));

  return (
    <div id="ServiceTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
            aria-label="Search"
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
            aria-label="Select Column"
          />
        </Col>
        <Col flex="auto">
          {setModalClosed && (
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalClosed(false)}
              >
                Add
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <DynamicTable
        data={filteredData}
        loading={fetchServiceLoading || removeLoading}
        customColumns={customColumns}
        scroll={{ x: 800 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default ServiceTable;

import { Layout } from "antd";
import Header from "../../../components/web/Header"; // Adjust the path as necessary
import Footer from "../../../components/web/Footer"; // Adjust the path as necessary
import LoginForm from "../../../components/web/LoginFrom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../../components/loader/Loader";
import SignUpForm from "../../../components/web/SignupFrom";

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <div>
      <>
        <Header loginVisible={false} />
        <SignUpForm />
        <Footer />
      </>
    </div>
  );
};

export default SignUp;

import { useEffect, useState } from "react";
import { Form, Button, message, Card, Input, Select } from "antd";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import usePost from "../../hooks/usePost";
import useFetch from "../../hooks/useFetch";
import { Link, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const resendInterval = 5;
  const [form] = Form.useForm();
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [codeRes, setCodeRes] = useState(null);

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginRes, setLoginRes] = useState(null);

  const [codeSent, setCodeSent] = useState(false);
  const [codeResend, setCodeResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(resendInterval);
  const [intervalId, setIntervalId] = useState(null);
  const [roles, setRoles] = useState([]);

  // Get the code from the server
  const { get: getCode, reset: resetGetCode } = useFetch("auth/get-code", {
    setLoading: setCodeLoading,
    setError: setCodeError,
    setData: setCodeRes,
  });

  const { post: login, reset: resetLogin } = usePost("auth/verify", {
    setLoading: setLoginLoading,
    setError: setLoginError,
    setData: setLoginRes,
  });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      await login(values);
      localStorage.setItem("role", values.role);
      setCodeResend(true);
      setResendTimer(resendInterval);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCode = async () => {
    try {
      const values = await form.validateFields(["phone"]);
      localStorage.setItem("phone", values.phone);
      console.log(values);
      await getCode(values);
      setCodeResend(true);
      setResendTimer(resendInterval);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const phone = localStorage.getItem("phone");
    if (phone) form.setFieldValue("phone", phone);
  }, []);

  useEffect(() => {
    if (codeResend && resendTimer > 0) {
      const id = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else if (resendTimer === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Cleanup on unmount or reset
  }, [codeResend, resendTimer]);

  useEffect(() => {
    console.log(codeRes);

    if (codeRes?.status)
      message[codeRes?.status](codeRes?.message || codeRes?.status);

    if (codeRes?.status === "success") {
      form.resetFields(["code", "role"]);
      setCodeSent(true);
    }
    if (codeRes?.users)
      setRoles(
        codeRes.users?.map((user) => ({
          label: user.role?.toUpperCase(),
          value: user.role,
        }))
      );
    resetGetCode();
  }, [codeRes]);

  useEffect(() => {
    console.log(codeError);
    if (codeError?.message) message[codeError?.status](codeError?.message);
    resetGetCode();
  }, [codeError]);

  useEffect(() => {
    console.log(loginRes);
    if (loginRes?.message) message[loginRes?.status](loginRes?.message);

    if (loginRes?.status === "success" && loginRes?.token) {
      localStorage.setItem("accessToken", loginRes?.token);
      navigate("/app");
    }

    resetLogin();
  }, [loginRes]);

  useEffect(() => {
    console.log(loginError);
    if (loginError?.message) message[loginError?.status](loginError?.message);
    resetLogin();
  }, [loginError]);

  return (
    <div
      style={{
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card title={`${codeSent ? "Verify" : "Login"}`}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          {!codeSent && (
            <>
              <PhoneNumberInput form={form} />
              <Form.Item>
                <Button
                  type="primary"
                  loading={codeLoading}
                  onClick={handleGetCode}
                >
                  Login
                </Button>
              </Form.Item>

              <Link to={"/signUp"}>Sign up</Link>
            </>
          )}

          {codeSent && (
            <>
              {roles.length && (
                <Form.Item
                  name="role"
                  initialValue={roles[0]?.value}
                  rules={[{ required: true, message: "Please select a role!" }]}
                >
                  <Select options={roles} placeholder="Select a role" />
                </Form.Item>
              )}
              <Form.Item
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input.OTP />
              </Form.Item>
              {codeResend && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <span>
                    {resendTimer > 0
                      ? `You can resend the code after ${resendTimer} seconds`
                      : "You can now resend the code"}
                  </span>
                  <Button
                    disabled={resendTimer > 0}
                    style={{ textAlign: "right" }}
                    type="link"
                    onClick={handleGetCode}
                  >
                    Resend
                  </Button>
                </div>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loginLoading}
                  >
                    Verify
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={() => setCodeSent(false)}>
                    Cancel
                  </Button>
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
